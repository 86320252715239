
import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';
import axios from 'axios';
import {
  SpecialPage,
  HotelDetail,
  SPECIAL_PAGE_PATH_DELIMITER,
  SpecialPageSpace,
  HotelBrandBasicInfo,
  UnAuthorized,
} from '~/schemes';
import { GFError } from '~/error';
import {
  HSpecialPageView,
  SpHeadSettings,
  createSpecialPageHead,
} from '~/components';

@Component<SpecialPageView>({
  scrollToTop: true,
  async asyncData({
    $hotel,
    $language,
    $i18n,
    $error,
    $dataBucket,
    params,
    res,
    error,
    redirect,
  }) {
    let hotel: HotelDetail | null | undefined;
    let brand: HotelBrandBasicInfo | undefined;
    const { pathMatch } = params;
    const slug = pathMatch.replace(/\//g, SPECIAL_PAGE_PATH_DELIMITER);
    try {
      const { hotel_slug, brand_slug } = params;
      let space: SpecialPageSpace = 'global';
      let target: string | undefined;

      if (hotel_slug) {
        hotel = $hotel.current;
        if (!hotel) hotel = await $hotel.getBySlug(hotel_slug);
        if (!hotel) return error({ statusCode: 404 });
        if (!hotel.id) return;
        space = 'hotel';
        target = hotel.id;
      }

      if (brand_slug) {
        brand = $hotel.brands.find((b) => b.slug === brand_slug);
        if (!brand) return error({ statusCode: 404 });

        space = 'brand';
        target = brand.id;
      }

      const page = await $dataBucket.getSpecialPage({
        space,
        target,
        slug,
      });

      // 外部URL取得
      const { externalUrl } = page;
      // 特集ページに外部URLが設定されていた場合はリダイレクトする;
      if (externalUrl) return redirect(externalUrl);

      if (process.server && page.isSecret) {
        res.setHeader('Cache-Control', 'no-store');
      }
      return { page };
    } catch (_err) {
      const err = GFError.from(_err);
      if (err.status === 404 && hotel) {
        const location = $hotel.location(undefined, hotel);
        return redirect(location);
      }
      if (err.status === 404 && brand) {
        return redirect(`/${$language.current}/brands/${brand.slug}/`);
      }
      if (err.status === 404) {
        const corporateSiteURL = $i18n.t('url.globalTop') as string;
        return redirect(corporateSiteURL);
      }

      if (err.status === 401) {
        if (process.server) {
          res.setHeader('Cache-Control', 'no-store');
        }

        if (axios.isAxiosError(_err) && _err.response) {
          // エラーレスポンスから認証ヘッダー取得
          const authHeader = _err.response.headers['www-authenticate'];
          // 認証ヘッダーが存在する場合、資格情報に紐づく認証を要求する
          if (authHeader) {
            res.setHeader('WWW-Authenticate', authHeader);
            return error({ statusCode: 401, message: UnAuthorized });
          }
        }
      }
      $error.throw(err);
    }
  },
  head() {
    const { hotel_slug, brand_slug, sp_slug } = this.$route.params;
    const { page } = this;
    const spHeadSettings: SpHeadSettings = {
      specialPage: page,
      ogUrl: `${this.$navigation.origin}/${this.$language.current}/sp/${sp_slug}`,
      headAttrs: {},
    };

    if (hotel_slug) {
      spHeadSettings.ogUrl = `${this.$navigation.origin}/${this.$language.current}/hotels/${hotel_slug}/sp/${sp_slug}`;
    }

    if (brand_slug) {
      spHeadSettings.ogUrl = `${this.$navigation.origin}/${this.$language.current}/brands/${brand_slug}/sp/${sp_slug}`;
      spHeadSettings.headAttrs = {
        // ブランドページのルートビューの方で「1」が設定されているせいで背景色があたっているので、
        // 特集ページではそれをさせないように空文字で上書き登録しておく。
        'data-foundation-background': '',
      };
    }

    return createSpecialPageHead(this, {
      specialPage: page,
      ogUrl: `${this.$navigation.origin}/${this.$language.current}/sp/${sp_slug}`,
    });
  },
  render() {
    return <HSpecialPageView specialPage={this.page} key={this.page.slug} />;
  },
})
export default class SpecialPageView extends Vue {
  page: SpecialPage = null as any;
}
